<template>
  <div>
    <form-section :title="$t('courierSet.setData')">
      <v-row>
        <v-col class="pb-0">
          <text-field
            v-model="getAuthorizationData.configurationName"
            :title="$t('courierSet.configurationName')"
            rules="required|not_custom_shipment"
          />
        </v-col>
        <v-col class="pb-0">
          <text-field
            v-model="getAuthorizationData.id"
            :title="$t('courierSet.configurationId')"
            rules="required"
            :disabled="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <select-field
            v-model="getAuthorizationData.courierCode"
            :title="$t('courierSet.deliverySpeditor')"
            rules="required"
            :filed-items="SHIPMENT_METHOD_CODES"
            disabled
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.authData')">
      <v-row>
        <v-col class="pb-0">
          <text-field
            v-model="getAuthorizationData.login"
            :title="$t('courierSet.login')"
            rules="required"
          />
        </v-col>
        <v-col class="pb-0">
          <text-field
            v-model="getAuthorizationData.password"
            :title="$t('courierSet.password')"
            rules="required"
            type="password"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.clientNumber"
            :title="$t('courierSet.clientNumber')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getAuthorizationData.url"
            :title="$t('courierSet.urlAddress')"
            rules="required"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import TextField from '@/components/inputs/TextField.vue';
import FormSection from '@/components/shared/FormSection.vue';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';

export default Vue.extend({
  components: {
    TextField,
    FormSection,
  },
  props: {
    isNew: { type: Boolean, default: false },
  },
  data: () => ({ SHIPMENT_METHOD_CODES }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getAuthorizationData() {
      return this.getConfigurationSet();
    },
  },
  mounted() {
    this.getAuthorizationData.courierCode = 'dbSchenker';
    if (this.isNew) {
      this.getAuthorizationData.id = uuidv4();
      this.getAuthorizationData.urlAddress =
        'https://sandbox.dhl24.com.pl/webapi2/provider/service.html?ws=1';
    }
  },
});
</script>
