export const DBSchenkerDefaultValues = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  installId: '',
  comment: '',
  deliveryInstructions: '',
  payer: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    clientId: '',
    name: '',
    postCode: '',
    city: '',
    street: '',
    nip: '',
    contactPerson: '',
  },
  dateTimeInfo: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    pickupFromHour: '',
    pickupWindowTimeInHours: 0,
    deliveryTimeIsSet: true,
    deliveryFromHour: '',
    deliveryWindowTimeInHours: 0,
  },
  product: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    colliId: 0,
    name: '',
    packCode: '',
    quantity: 0,
    protection: '',
    weight: 0,
    volume: 0,
    width: 0,
    length: 0,
    height: 0,
    stack: true,
    notStandard: true,
    nstReason: '',
  },
  adr: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    unNo: '',
    group: '',
    weight: 0,
    colli: 0,
    package: 0,
    notAdrRule: true,
    notes: '',
    name: '',
  },
  references: [
    {
      clientEntityState: 4,
      id: '',
      tenantId: '',
      type: 0,
      number: '',
    },
  ],
};
